import React from "react";

const Confirm = () => (
    <div className="page-container">
        <p>
            Unable to find subscription.
        </p>
    </div>
);

export default Confirm;
